import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMIconButton } from '../RMIconButton/RMIconButton';

export const Skeleton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100%);
  background: linear-gradient(90deg, #283e3e 0%, #3c5d5d 52.3%, #2b4141 100%);
  animation: shimmer 1.5s infinite ease-in-out;
  overflow: hidden;

  &[data-variant='light'] {
    background: linear-gradient(270deg, #eae8e1 26.76%, #f7f6f3 48.81%, #eae8e1 73.81%);
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`;

export const Image = styled.img`
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: fit-content;
  height: auto;
  background-color: var(--on-surface-primary);

  &[data-loaded='true'] {
    background-color: transparent;

    ${Skeleton} {
      display: none;
    }
  }

  &[data-variant='light'] {
    background: var(--surface-dim);
  }
`;

export const FullscreenButton = styled(RMIconButton)`
  position: absolute;
  top: var(--spacing-xs);
  right: var(--spacing-xs);
  z-index: 2;
  backdrop-filter: none !important;
`;

export const FullscreenContent = styled(motion.div)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100dvh;
  top: 0;
  left: 0;
  background-color: var(--surface-always-dark);
  z-index: 2;

  img {
    border-radius: unset;
    border: none;
    max-width: calc(100% - 10rem);
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    margin: 0 auto;

    body[data-mobile='true'] & {
      max-height: calc(100% - 3.5rem);
      max-width: 100%;
    }
  }
`;

export const CloseButton = styled(RMIconButton)`
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);

  body[data-mobile='true'] & {
    top: var(--spacing-xs);
    right: var(--spacing-xs);
  }
`;
