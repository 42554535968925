import { ComponentProps, forwardRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { faClose, faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-solid-svg-icons';

import { CloseButton, FullscreenButton, FullscreenContent, Image, Skeleton, Wrapper } from './RMImage.styles';

type RMImageVariant = 'dark' | 'light';

type RMImageProps = Omit<ComponentProps<'img'>, 'src'> & {
  src?: string | null | undefined;
  variant?: RMImageVariant;
  fullscreenSrc?: string | null;
  fullscreenEnabled?: boolean;
};

export const RMImage = forwardRef<HTMLImageElement, RMImageProps>(
  ({ src, style, className, variant = 'dark', fullscreenSrc, fullscreenEnabled = false, ...props }, ref) => {
    const [loaded, setLoaded] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.target instanceof HTMLTextAreaElement) {
          return;
        }

        if (event.key === 'Escape' && fullscreen) {
          setFullscreen(false);
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [fullscreen]);

    const portalRoot = document.getElementById('dialog');
    if (!portalRoot) {
      console.error('Missing portal root for dialog');
      return null;
    }

    return (
      <Wrapper style={style} className={className} data-loaded={loaded} data-variant={variant}>
        {fullscreenEnabled && (
          <FullscreenButton
            icon={faUpRightAndDownLeftFromCenter}
            size="xl"
            backgroundColor="transparent"
            stateColor="darken-neutral"
            tooltip={null}
            color="white"
            shadow
            onClick={() => setFullscreen(true)}
          />
        )}
        <Skeleton data-variant={variant} />
        {src != null && (
          <>
            <Image ref={ref} src={src} style={style} className={className} onLoad={() => setLoaded(true)} {...props} />
            {fullscreen &&
              createPortal(
                <FullscreenContent initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <Image src={fullscreenSrc ?? src} />
                  <CloseButton icon={faClose} tooltip={null} size="xl" onClick={() => setFullscreen(false)} />
                </FullscreenContent>,
                portalRoot,
              )}
          </>
        )}
      </Wrapper>
    );
  },
);
